import React from 'react';

import { FormHelperText, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as Alert } from 'assets/images/tabler-icon/input-alert.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { remCalc } from 'theme/utils';

interface ErrorMessageProps {
  message: string | null | string[];
}

const useStyles = makeStyles()(() => ({
  helper: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    fontSize: remCalc(12),
  },
}));

function ErrorMessage({ message }: ErrorMessageProps): JSX.Element {
  const { classes } = useStyles();

  const getErrorMessage = (): string | null => {
    if (Array.isArray(message)) {
      return message.join(', ');
    }

    return message;
  };

  return (
    <FormHelperText sx={{ color: '#DF2D2D', m: 0, marginTop: '5px' }} data-testid='error-message'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <SVGIcon icon={Alert} fontSize='small' />
        <span className={classes.helper}>{getErrorMessage()}</span>
      </Stack>
    </FormHelperText>
  );
}

export default ErrorMessage;
