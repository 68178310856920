export const remCalc = (size: number, base = 16): string => `${size / base}rem`;
export default remCalc;

export const getErrorStyle = (error: boolean, styleOnly?: boolean): Record<string, unknown> => {
  if (error) {
    return styleOnly
      ? {
          border: '1px solid #D92D20',
          background: '#FEF3F2',
        }
      : {
          '& .MuiInputBase-root': {
            border: '1px solid #D92D20',
            background: '#FEF3F2',
          },
        };
  }

  return {};
};
