import { backendBaseUrl } from './env';

export const API_BASE_URL = backendBaseUrl;

export const APIQueryConstant = {
  searchQuery: 'searchQuery',
};

export const APIS = {
  OVERVIEW: {
    DASHBOARD_OVERVIEW: 'overview',
    DASHBOARD_lOCATION: '/overview/locations',
  },
  COMMON: {
    UPLOAD: 'files',
  },
  SETTINGS: {
    // ORG
    GET_ALL_ORGANISATION: 'organisations',
    GET_ORGANISATION: 'organisations',
    GET_ORGANISATION_DETAILS: 'organisations',
    ADD_ORGANISATION: 'organisations',
    UPDATE_ORGANISATION: 'organisations/',
    DELETE_ORGANISATION: 'organisations/',
    HISTORY_ORGANISATION: 'organisations/:id/history',

    GET_CONNECTIONS: '/devices/connections',
    ADD_CONNECTIONS: '/devices/connections',
    UPDATE_CONNECTIONS: '/devices/connections/',
    DELETE_CONNECTIONS: '/devices/connections/',

    // User
    USERS_LIST: 'users',
    GET_USER: 'users',
    ADD_USER: 'users',
    UPDATE_USER: 'users/',
    DELETE_USER: 'users/',
    ME: 'me',
    ME_INVITES: 'me/invites/',
    HISTORY_USER: 'users/:id/history',
    UNLINK_ORG: '/me/organisations/',

    // ORG Location
    GET_LOCATION_DETAILS: 'organisation-locations',
    CREATE_ORG_LOCATION: 'organisation-locations',
    GET_ORG_LOCATION: 'organisation-locations',
    UPDATE_ORG_LOCATION: 'organisation-locations/',
    DELETE_LOCATION: 'organisation-locations/',
    HISTORY_LOCATION: 'organisation-locations/:id/history',

    // ORG GROUPS
    GET_GROUP_DETAILS: 'organisation-location-groups',
    CREATE_GROUPS: 'organisation-location-groups',
    GET_GROUPS: 'organisation-location-groups',
    UPDATE_GROUPS: 'organisation-location-groups/',
    DELETE_GROUPS: 'organisation-location-groups/',
    GET_GROUP_LOCATION_DETAILS: 'organisation-locations',
    DELETE_GROUP_LOCATION: 'organisation-location-groups/:id/locations/',

    CREATE_API_KEY: 'api-keys',
    GET_API_KEY: 'api-keys',
    UPDATE_API_KEY: 'api-keys/',
    DELETE_API_KEY: 'api-keys/',
    ROLL_API_KEY: 'api-keys/:id/roll',

    // Additional fields

    ADD_ADDITIONAL_FIELDS: 'additional-fields',
    UPDATE_ADDITIONAL_FIELDS: 'additional-fields/',
    GET_ADDITIONAL_FIELDS: 'additional-fields',
    DELETE_ADDITIONAL_FIELDS: 'additional-fields/',

    GET_ASSET_TYPES: 'asset-types',
    GET_ASSET_MODELS: 'models',
    GET_ASSET_MAKES: 'makes',

    ADD_ASSET_TYPES: 'asset-types',
    ADD_ASSET_MODELS: 'models',
    ADD_ASSET_MAKES: 'makes',

    UPDATE_ASSET_TYPES: 'asset-types/',
    UPDATE_ASSET_MODELS: 'models/',
    UPDATE_ASSET_MAKES: 'makes/',

    DELETE_ASSET_TYPES: 'asset-types/',
    DELETE_ASSET_MODELS: 'models/',
    DELETE_ASSET_MAKES: 'makes/',

    PARTS_LIST: 'parts',

    ADD_JOB_TYPE_CONFIG: 'job-types',
    UPDATE_JOB_TYPE_CONFIG: 'job-types/',
    GET_JOB_TYPE_CONFIG: 'job-types',
    DELETE_JOB_TYPE_CONFIG: 'job-types/',

    GET_CATEGORIES: 'categories',
    GET_CATEGORIES_PARTS: 'categories/parts',
    ADD_CATEGORIES: 'categories',
    UPDATE_CATEGORIES: 'categories/',
    DELETE_CATEGORIES: 'categories/',

    ADD_JOB_ACTIVITIES: 'job-activities',
    UPDATE_JOB_ACTIVITIES: 'job-activities/',
    GET_JOB_ACTIVITIES: 'job-activities',
    DELETE_JOB_ACTIVITIES: 'job-activities/',

    GET_API_KEYS: 'api-keys',
  },
  ASSETS: {
    ASSET_LIST: 'assets',
    GET_ASSET_ASSIGNMENT_HISTORY_LIST: 'assets',
    GET_ASSET_JOB_LIST: 'asset-list/job',
    ADD_ASSET: 'assets',
    UPDATE_ASSET: 'assets/',
    GET_ASSET: 'assets',
    GET_ASSET_ACTIVITY_FEED: 'assets',
    DELETE_ASSET: 'assets/',
  },
  PARTS: {
    PARTS_LIST: 'parts',
    ADD_PART: 'parts',
    UPDATE_PART: 'parts/',
    DELETE_PART: 'parts/',
    GET_PART: 'parts',
    HISTORY_PARTS: 'parts/:id/history',
  },
  JOBS: {
    ADD_JOB: 'jobs',
    GET_JOB_DETAILS: 'jobs',
    UPDATE_JOB: 'jobs/',
    GET_JOBS: 'jobs',
    ADD_ACTIVITY_LOG: 'jobs/:id/activities',
    ADD_LOG_ACTION: 'jobs/:id/actions',
    EXPORT_JOB: 'jobs/export',
    EXPORT_ASSET: 'assets/export',
  },
  TELEMATICS: {
    GET_DEVICES: 'devices',
    ADD_DEVICES: 'devices',
    UPDATE_DEVICES: 'devices/',
    GET_DEVICE: 'devices',
    GET_STRUCTURE: 'devices/structures',
    DELETE_DEVICE: 'devices/',
  },
  FEATURES: {
    GET_FEATURES: 'features',
  },
};
