import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { addUrlId } from 'utils/commonUtils';

const {
  USER: {
    USER_DETAILS,
    SETTINGS: { USERS_ASSIGNMENT_HISTORY },
  },
} = ROUTES;

export function UserDetailsRedirect(): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      navigate(addUrlId(USER_DETAILS, id));
    }
  }, [id, navigate]);

  return null;
}

export function UserDetailsHistoryRedirect(): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      navigate(addUrlId(USER_DETAILS + USERS_ASSIGNMENT_HISTORY, id));
    }
  }, [id, navigate]);

  return null;
}
